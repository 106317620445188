<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- profile user -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.profile.singular.profileUser') }}</span>
      </template>

      <profile-user />
    </b-tab>
    <!--/ profile user -->

    <!-- update password -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="KeyIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('apps.profile.singular.password') }}</span>
      </template>

      <password />
    </b-tab>
    <!--/ update password -->

  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ProfileUser from './ProfileUser.vue'
import Password from './Password.vue'

export default {
  components: {
    BTabs,
    BTab,
    ProfileUser,
    Password
  },
  data () {
    return {
      options: {}
    }
  },
  beforeCreate () {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  }
}
</script>
