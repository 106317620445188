<template>
  <b-card>
    <span>{{ $t('apps.profile.singular.fillTheFormBelow') }}</span>

    <!-- form -->
    <validation-observer ref="formRef">
      <b-form @submit.prevent class="mt-2">

        <b-row>
          <!-- user name -->
          <b-col md="8">
            <b-form-group
              :label="this.$t('apps.profile.singular.password')"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    size="sm"
                    v-model="form.password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ company name -->

        </b-row>

        <b-row>
          <!-- email -->
          <b-col md="8">
            <b-form-group
              :label="$t('apps.profile.singular.confirmPassword')"
              label-for="passwordConfirmation"
            >
              <validation-provider
                #default="{ errors }"
                mode="passive"
                name="passwordConfirmation"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="passwordConfirmation"
                    size="sm"
                    v-model="form.passwordConfirmation"
                    name="passwordConfirmation"
                    :type="passwordConfirmationFieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordConfirmationToggleIcon"
                      @click="togglePasswordConfirmationVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ email -->

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0">

            <b-button
              variant="primary"
              type="submit"
              @click="handleSubmit"
            >
              <feather-icon
                icon="SaveIcon"
              />
              {{ $t('globalActions.update') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BRow,
  BCol,
  BCard,
  BButton
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, confirmed } from '@validations'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    ValidationObserver,
    ValidationProvider
  },
  setup () {
    const passwordFieldType = ref('password')
    const passwordConfirmationFieldType = ref('password')
    const form = ref({
      password: '',
      passwordConfirmation: ''
    })

    const { toast, $put } = useHttp()

    return {
      form,
      $put,
      toast,
      required,
      confirmed,
      passwordFieldType,
      passwordConfirmationFieldType
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordConfirmationToggleIcon () {
      return this.passwordConfirmationFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    togglePasswordVisibility () {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirmationVisibility () {
      this.passwordConfirmationFieldType = this.passwordConfirmationFieldType === 'password' ? 'text' : 'password'
    },
    handleSubmit () {
      this.$refs.formRef.validate().then(success => {
        if (success) {
          const $swal = this.$swal

          $swal({
            title: `${this.$t('confirm.updateThis')} ${this.$t('apps.profile.singular.password')} ?`,
            icon: 'question',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-flat-warning'
            }
          }).then(async () => {
            const callbackSuccess = () => {
              this.toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  variant: 'success',
                  text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} ${this.$t('apps.profile.singular.password')}.`
                }
              })
            }

            this.$put({
              data: {password: this.form.password},
              url: `users/${this.$store.state.user.id}/password`
            }).then((res) => callbackSuccess(res))
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
